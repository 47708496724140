import { frontendURL } from '../../../helper/URLHelper';
const SalesFunnelIndex = () => import('./pages/Index.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/salesfunnel'),
    name: 'salesfunnel_dashboard',
    meta: {
      permissions: ['administrator', 'agent'],
    },
    component: SalesFunnelIndex,
  },
];
